
import MyDesk from "@/components/Monitoring/MyDesk.vue";
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";
import MonitoringIndicators from "@/components/Monitoring/MonitoringIndicators.vue";
import { currentUserStore } from "@/store/typed";
import IndicatorFilters from "@/components/Indicators/Filters.vue";
import ReviewIndicators from "@/components/Indicators/Review.vue";
import MonitoringQualitativeIndicators from "@/components/Monitoring/QualitativeIndicators.vue";
import ExportData from "@/components/Indicators/ExportData.vue";
import { printStore } from "../../store/typed";

@Component({
  components: {
    MyDesk,
    MonitoringIndicators,
    PageSection,
    IndicatorFilters,
    StickyMenu,
    ReviewIndicators,
    MonitoringQualitativeIndicators,
    ExportData,
  },
})
export default class MonitoringDashboard extends Vue {
  private showFilters = false;

  private links: StickyMenuLink[] = [
    { routeName: "monitoring_post_feed", icon: "it-inbox", name: "Forum/wiki" },
    {
      routeName: "monitoring_initiative_map",
      icon: "it-pin",
      name: "Hub del riuso",
    },
    {
      routeName: "monitoring_dashboard",
      icon: "it-presentation",
      name: "Dashboard",
    },
  ];

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }

  get printing() {
    return printStore.printing;
  }
}
