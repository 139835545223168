
import { Vue, Mixins, Component, Prop } from "vue-property-decorator";
import Gauge from "@/components/EChart/Gauge.vue";
import Spinner from "@/components/Common/Spinner.vue";
import SupplierQualityIndicator from "@/models/SupplierQualityIndicator";
import { RegisterHttp } from "@/utils/Decorators";
import { IndicatorMixinTags } from "@/mixins/http/IndicatorMixin";
import RequestData from "@/store/types/RequestData";
import {
  CHART_PRIMARY_COLOR,
  INDICATOR_DESCRIPTIONS,
} from "../../../utils/Constants";

@Component({
  components: {
    Spinner,
    Gauge,
  },
})
export default class MonitoringQualitativeIndicatorItem extends Vue {
  @Prop({ required: true }) value!: SupplierQualityIndicator;

  @RegisterHttp(IndicatorMixinTags.MonitoringQualitative)
  indicatorsRequest!: RequestData;

  get label() {
    return "";
  }

  get palette() {
    return CHART_PRIMARY_COLOR;
  }

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  get description() {
    const code = `${this.value.category}-${this.value.code}`;
    return INDICATOR_DESCRIPTIONS[code]?.short;
  }

  get measurementUnit() {
    if (this.value.unit == "n") {
      return "";
    }

    return this.value.unit;
  }
}
