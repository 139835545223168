
import { Vue, Component, Prop } from "vue-property-decorator";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { RadarChart } from "echarts/charts";
import {
  ChartTitle,
  PieChartEntry,
  RadarEntry,
  RadarIndicator,
} from "@/models/echart/types";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";

import VChart from "vue-echarts";
import { CHART_PRIMARY_COLOR } from "../../utils/Constants";

use([
  CanvasRenderer,
  RadarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

@Component({
  components: {
    VChart,
  },
})
export default class EChartRadar extends Vue {
  @Prop({ required: true }) readonly indicators!: RadarIndicator[];
  @Prop({ required: true }) readonly values!: RadarEntry[];
  @Prop({ default: "radar" }) readonly type!: "radar";

  get isMobile() {
    return this.$mq === "sm";
  }

  get chartIndicators(): RadarIndicator[] {
    if (!this.indicators) {
      return [];
    }

    if (!this.isMobile) {
      return this.indicators;
    }

    return this.indicators.map((el: RadarIndicator) => {
      return { name: el.name.substring(0, 4), max: el.max };
    });
  }

  get chartData() {
    if (!this.values) {
      return { value: [] };
    }

    const parsed = this.values.map((el: RadarEntry) => {
      return el.value;
    });

    return { value: parsed };
  }

  get option() {
    return {
      radar: {
        indicator: this.chartIndicators,
      },

      series: [
        {
          type: this.type,
          itemStyle: {
            color: CHART_PRIMARY_COLOR,
          },
          areaStyle: {
            color: CHART_PRIMARY_COLOR,
          },

          label: {
            show: true,
            color: "black",
            fontSize: "14",
            position: "bottom",
          },
          data: [this.chartData],
        },
      ],
    };
  }
}
