
import { Vue, Component, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import LikeButton from "@/components/Like/Button.vue";
import CreateCard from "@/components/Post/CreateCard.vue";
import CreatePostModal from "@/components/Post/CreateModal.vue";
import PostList from "@/components/Post/List.vue";
import { currentUserStore } from "@/store/typed";

@Component({
  components: {
    PageSection,
    LikeButton,
    CreateCard,
    CreatePostModal,
    PostList,
  },
})
export default class MyDesk extends Vue {
  @Ref() readonly createPostModal!: any;

  @Ref() readonly postList!: any;

  onAdd(typeId: string): void {
    (this.createPostModal as any).create(typeId);
  }

  onCreated(): void {
    (this.postList as any).reload();
  }

  get disabled(): boolean {
    return (
      currentUserStore.user?.isOccUser || currentUserStore.user?.isConsipUser
    );
  }
}
