
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";

@Component
export default class VPopover extends Vue {
  @Prop({ default: "top" }) readonly direction!:
    | "top"
    | "bottom"
    | "left"
    | "right";

  @Prop() readonly title!: string | null;
  @Prop() readonly body!: string | null;

  get popperOptions() {
    return {
      placement: this.direction,
    };
  }
}
