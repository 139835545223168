
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Initiative, Organization } from "../../models";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import InitiativeSideRecapItem from "@/components/Initiative/SideRecapItem.vue";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {
    InitiativeSideRecapItem,
  },
})
export default class SideRecap extends Mixins(InitiativeMixin) {
  @Prop({ required: true }) readonly organization!: Organization;

  private initiatives: Initiative[] = [];

  contact() {
    this.$emit("contact", this.organization);
  }

  @Watch("organization", { deep: true, immediate: true })
  onOrganizationChanged(value: any, oldValue: any) {
    this.loadInitiatives();
  }

  get payload() {
    return {
      publishable: "1",
      organization: this.organization.id,
    };
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get myOwn() {
    return this.organization.id === this.currentUser.organization?.id;
  }

  get contactDisabled() {
    return !this.organization?.mail;
  }

  async loadInitiatives() {
    const include = "contract.contract_service.service";

    const [data, errors] = await safeAsync(
      this.getInitiatives("all", this.payload)
    );

    if (data) {
      this.initiatives = data;
    } else {
      console.error(errors);
    }
  }
}
