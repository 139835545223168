
import { Component, Ref, Watch, Mixins } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Initiative, Post } from "../../models";
import WithErrors from "@/directives/WithErrors";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import PostMixin, { PostMixinTags } from "@/mixins/http/PostMixin";
import { PostPayload } from "../../models/Post";
import { safeAsync } from "@/utils/AsyncUtil";
import InitiativeSelect from "@/components/Initiative/Select.vue";
import { showErrorToast } from "@/utils/Toast";
import { currentUserStore, httpStore } from "../../store/typed";
import { showToast } from "@/utils/Toast";
import VFileUploader from "@/components/Common/VFileUploader.vue";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    VModal,
    VFileUploader,
    InitiativeSelect,
  },
})
export default class CreatePostModal extends Mixins(PostMixin) {
  @Ref() readonly modal!: VModal;
  @RegisterHttp(PostMixinTags.PostCreate) readonly httpRequest!: RequestData;

  private type: string | null = null;
  private post: PostPayload = PostPayload.empty();
  private termsApproved = false;
  private checkboxError = false;
  private imageFile: any = null;
  private documentFiles: any[] = [];
  private imageButtonHovered = false;
  private documentsButtonHovered = false;

  @Ref() readonly postText!: HTMLElement;

  @Watch("termsApproved")
  onTermsChanged(value: boolean) {
    if (this.checkboxError && value) {
      this.checkboxError = false;
    }
  }

  detachDocument(index: number) {
    this.documentFiles.splice(index, 1);
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get organizationId() {
    return this.currentUser?.organization?.id;
  }

  detachImage() {
    this.imageFile = null;
  }

  get isBreakingNews() {
    return this.type === "breaking_news";
  }

  get isEvent() {
    return this.type === "event";
  }

  get isWebinar() {
    return this.type === "webinar";
  }

  get title() {
    return "Crea un post";
  }

  get httpTag() {
    return PostMixinTags.PostCreate;
  }

  get uploadTag() {
    return PostMixinTags.PostAddImage;
  }

  get loading() {
    return (
      httpStore.status[this.httpTag]?.loading ||
      httpStore.status[this.uploadTag]?.loading
    );
  }

  get postTypes() {
    return Post.types;
  }

  create(type: string) {
    this.type = type;
    (this.modal as any).show();

    this.$nextTick(() => {
      this.postText.focus();
    });
  }

  hide() {
    (this.modal as any).hide();
  }

  onCreated(initiative: Initiative) {
    this.$emit("created", initiative);
    this.hide();
  }

  reset() {
    this.post = PostPayload.empty();
  }

  async onUpload() {
    this.checkboxError = !this.termsApproved;
    if (!this.termsApproved) {
      return;
    }
    this.post.postType = this.type;

    const [data, errors] = await safeAsync(this.createPost(this.post));
    if (data) {
      this.uploadMedia(data);
    } else {
      showErrorToast("Si è verificato un errore");
    }
  }

  async uploadMedia(post: Post) {
    const formData = new FormData();
    if (this.imageFile?.file) {
      formData.append("image", this.imageFile.file);
    }

    if (this.documentFiles) {
      for (let i = 0; i < this.documentFiles.length; i++) {
        formData.append(`documents[]`, this.documentFiles[i].file);
      }
    }
    const [data, errors] = await safeAsync(this.addMedia(post.id, formData));
    if (data) {
      this.onCreated(data);
      this.reset();
      showToast("Post creato!");
    } else {
      showErrorToast("Si è verificato un errore");
    }
  }
}
