
import IndicatorMixin from "@/mixins/http/IndicatorMixin";
import { Vue, Component, Mixins } from "vue-property-decorator";
import EChartRadar from "@/components/EChart/Radar.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import ReviewIndicator from "@/models/ReviewIndicator";
import { RadarEntry, RadarIndicator } from "@/models/echart/types";
import { monitoringStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";

@Component({
  components: {
    EChartRadar,
  },
})
export default class ReviewIndicators extends Vue {
  private indicators: ReviewIndicator | null = null;

  mounted() {
    this.loadIndicators();
    StoreSubscriber.subscribe("monitoring/_setFilter", this.onFilterChanged);
  }

  get supplierIndicators(): RadarIndicator[] {
    if (!this.indicators) {
      return [];
    }

    return this.indicators.supplierValues.map((el: any) => {
      return { name: el.name, max: el.max };
    });
  }

  average(list: RadarEntry[]) {
    const sum = list.reduce((a, b) => a + b.value, 0);
    return sum / list.length;
  }

  get supplierValues(): RadarEntry[] {
    if (!this.indicators) {
      return [];
    }

    return this.indicators.supplierValues.map((el: any) => {
      return { name: el.name, value: el.value };
    });
  }

  get servicesIndicators(): RadarIndicator[] {
    if (!this.indicators) {
      return [];
    }

    return this.indicators.servicesValues.map((el: any) => {
      return { name: el.name, max: el.max };
    });
  }

  get servicesValues(): RadarEntry[] {
    if (!this.indicators) {
      return [];
    }

    return this.indicators.servicesValues.map((el: any) => {
      return { name: el.name, value: el.value };
    });
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadIndicators();
    });
  }

  async loadIndicators() {
    const data = await monitoringStore.getReviewIndicators();

    if (data) {
      this.indicators = data;
    }
  }
}
