
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import OsmMap from "@/components/OsmMap.vue";
import OrganizationSideRecap from "@/components/Organization/SideRecap.vue";
import OrganizationMixin, {
  OrganizationTags,
} from "@/mixins/http/OrganizationMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { Initiative, Organization } from "../../models";
import MapModal from "@/components/Initiative/MapModal.vue";
import RegionSelect from "@/components/RegionSelect.vue";
import OrganizationSelect from "@/components/Organization/Select.vue";
import OrganizationKindSelect from "@/components/Organization/KindSelect.vue";
import { currentUserStore, httpStore, zoneStore } from "../../store/typed";
import OrganizationContactModal from "@/components/Organization/ContactModal.vue";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";
import TagMultiselect from "@/components/Common/TagMultiselect.vue";

@Component({
  components: {
    TagMultiselect,
    OsmMap,
    MapModal,
    RegionSelect,
    OrganizationSelect,
    OrganizationKindSelect,
    OrganizationSideRecap,
    OrganizationContactModal,
    VThrottlerInput
  },
})
export default class InteractiveMap extends Mixins(OrganizationMixin) {
  private organizations: Organization[] = [];

  @Ref() readonly mapModal!: any;
  @Ref() readonly organizationContactModal!: OrganizationContactModal;

  @Prop({ default: false }) readonly showFullscreen!: boolean;
  @Prop() readonly config!: any;

  private showFilters = false;

  private selectedTags: string[] = [];

  private selectedOrganization: Organization | null = null;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get realmFilter() {
    return { realm: zoneStore.realm?.id || "" };
  }

  get initiativeTermFilter() {
    if (this.initiativeTerm) {
      return { initiativeTerm: this.initiativeTerm };
    } else return {};
  }

  get tagFilter() {
    if (this.selectedTags.length) {
      return { initiativeTags: this.selectedTags };
    } else return {};
  }

  private region = "";
  private organizationId = "";
  private kind = "";
  private initiativeTerm = "";

  mounted() {
    this.loadInitiatives();
  }

  get showMap() {
    return !this.isMobile || (this.isMobile && !this.selectedOrganization);
  }

  get showMobileSideRecap() {
    return this.isMobile && this.selectedOrganization;
  }

  resetFilters() {
    this.region = "";
    this.organizationId = "";
    this.kind = "";
  }

  @Watch("showFilters")
  onShowFiltersChanged(value: boolean) {
    if (!value) {
      this.loadInitiatives();
    }
  }

  onFullscreenSelected() {
    this.$emit("fullscreen");
  }

  onSelected(id: string) {
    this.selectedOrganization = this.organizations.find(
      (o: Organization) => o.id === id
    );
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get payload() {
    return {
      region: this.region,
      withPublishableInitiatives: true,
      kind: this.kind,
      publishable: "1",
      organization: this.organizationId,
      ...this.realmFilter,
      ...this.initiativeTermFilter,
      ...this.tagFilter,
    };
  }

  get resources() {
    return this.organizations
      .filter((el: Organization) => el.lon && el.lat)
      .map((o: Organization) => {
        return {
          id: o.id,
          coords: [parseFloat(o.lon.toString()), parseFloat(o.lat.toString())],
          label: o.name,
        };
      });
  }

  onOrganizationKindSelected(id: string) {
    this.kind = id !== "all" ? id : null;
    this.loadInitiatives();
  }

  onRegionSelected(id: string) {
    this.region = id !== "all" ? id : null;
    this.loadInitiatives();
  }

  onOrganizationSelected(id: string) {
    this.organizationId = id !== "all" ? id : null;
    this.loadInitiatives();
  }

  onTagSelected(value: any) {
    this.selectedTags = value;
    this.loadInitiatives();
  }

  get center() {
    return this.resources?.[0]?.coords;
  }

  get loading() {
    return httpStore.status[OrganizationTags.OrganizationIndex]?.loading;
  }

  @Watch("initiativeTerm")
  async loadInitiatives() {
    const [data, errors] = await safeAsync(
      this.getOrganizations("all", this.payload)
    );

    if (data) {
      this.organizations = data;
    }
  }

  private onContactOrganization(organization: Organization) {
    this.organizationContactModal.show(organization.id);
  }
}
