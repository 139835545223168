import { httpStore } from "@/store/typed";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";

export enum RegionTags {
  RegionIndex = "RegionIndex",
}

@Component
export default class RealmMixin extends Vue {
  getRegions(filter: any): Promise<string[]> {
    const url = `${SD_BASE_API_URL}/regions`;

    return httpStore.request({
      tag: RegionTags.RegionIndex,
      url: url,
      method: "GET",
      params: filter,
      noParse: true,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<string[]>;
  }
}
