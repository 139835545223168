
import { Component, Watch, Mixins } from "vue-property-decorator";
import ExecutiveContractTrendItem from "@/components/Indicators/ExecutiveContractTrend/Item.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import StoreSubscriber from "@/utils/StoreSubscriber";
import { ExecutiveContractTrendIndicator } from "@/models";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import Spinner from "@/components/Common/Spinner.vue";
import { ExecutiveContractTrendReport } from "@/models";
import { monitoringStore } from "../../../store/typed";
const MILLION = 1000000;
const THOUSAND = 1000;
@Component({
  components: {
    ExecutiveContractTrendItem,
    Spinner,
  },
})
export default class ExecutiveContractTrendList extends Mixins(IndicatorMixin) {
  private data: ExecutiveContractTrendIndicator[] = [];
  private absoluteValues = false;

  @RegisterHttp(IndicatorMixinTags.IndicatorExecutiveContractsTrend)
  indicatorsRequest!: RequestData;

  created() {
    this.loadIndicators();
    StoreSubscriber.subscribe("monitoring/_setFilter", this.onFilterChanged);
  }

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  economicDataValue(value: number) {
    return (value / MILLION).toFixed(1);
  }

  get reports(): ExecutiveContractTrendReport[] {
    if (!this.data.length) {
      return null;
    }

    return this.data.map((o: ExecutiveContractTrendIndicator) => {
      const suffix = this.absoluteValues ? "" : "%";
      const value = (
        this.absoluteValues ? o.data.absolute : o.data.percentage
      ).toFixed(2);
      const max = (
        this.absoluteValues
          ? (o.data.absolute / (o.data.percentage / 100)).toFixed(2)
          : 100
      ) as number;

      const item = new ExecutiveContractTrendReport();

      if (o.category === "IM" && o.code === "6" && this.absoluteValues) {
        item.code = o.code;
        item.category = o.category;
        item.data = parseFloat(this.economicDataValue(parseFloat(value)));
        (item.suffix = "M"),
          (item.max = parseFloat(this.economicDataValue(max)));
      } else {
        item.code = o.code;
        item.category = o.category;
        item.data = parseFloat(this.economicDataValue(parseFloat(value)));
        item.suffix = suffix;
        item.max = max;
      }

      return item;
    });
  }

  get filter() {
    return monitoringStore.filter;
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadIndicators();
    });
  }

  private async loadIndicators() {
    const data = await monitoringStore.getExecutiveContractsTrend();
    if (data) {
      this.data = data.indicators;
    }
  }
}
