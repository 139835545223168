
import { Mixins, Component, Ref } from "vue-property-decorator";
import DescriptionModal from "@/components/Common/DescriptionModal.vue";
import Gauge from "@/components/EChart/Gauge.vue";
import { CHART_PRIMARY_COLOR } from "@/utils/Constants";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { monitoringStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import Spinner from "@/components/Common/Spinner.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import MonitoringQualitativeIndicatorItem from "@/components/Indicators/MonitoringQualitative/Item.vue";
import SupplierQualityIndicator from "@/models/SupplierQualityIndicator";
import { INDICATOR_DESCRIPTIONS } from "../../../utils/Constants";

@Component({
  components: {
    Spinner,
    DescriptionModal,
    Gauge,
    MonitoringQualitativeIndicatorItem,
  },
})
export default class MonitoringQualitativeIndicatorList extends Mixins(
  IndicatorMixin
) {
  private report: SupplierQualityIndicator[] = [];

  @Ref() readonly descriptionModal!: any;

  @RegisterHttp(IndicatorMixinTags.MonitoringQualitative)
  indicatorsRequest!: RequestData;

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  get count() {
    return this.report[0]?.contractsCount;
  }

  openDescriptionModal(indicator: SupplierQualityIndicator) {
    const fullCode = `${indicator.category}-${indicator.code}`;
    const description =
      INDICATOR_DESCRIPTIONS[fullCode]?.long ||
      "Nessuna descrizione disponibile";
    const title = `Indicatore di qualità della fornitura ${fullCode}`;
    this.descriptionModal.show(description, title);
  }

  get palette() {
    return [CHART_PRIMARY_COLOR];
  }

  mounted() {
    this.loadData();
    StoreSubscriber.subscribe("monitoring/_setFilter", this.onFilterChanged);
  }

  get filter() {
    return monitoringStore.filter;
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadData();
    });
  }

  get payload() {
    return {
      filter: {
        ...this.filter,
      },
    };
  }

  async loadData() {
    const data = await monitoringStore.getQualitativeIndicators();

    if (data) {
      this.report = data;
    }
  }
}
