
import { Component, Mixins, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import OsmMap from "@/components/OsmMap.vue";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { Initiative } from "../../models";
import MapModal from "@/components/Initiative/MapModal.vue";
import InitiativeMap from "@/components/Initiative/Map.vue";

@Component({
  components: {
    PageSection,
    OsmMap,
    InitiativeMap,
    MapModal,
  },
})
export default class InteractiveMap extends Mixins(InitiativeMixin) {
  @Ref() readonly mapModal!: any;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get title() {
    return this.isMobile ? "" : "Hub del riuso";
  }

  get icon() {
    return this.isMobile ? "" : "it-pin";
  }

  onFullscreenSelected() {
    (this.mapModal as any).show();
  }

  get mapConfig() {
    return { zoom: 2, minZoom: 5, maxZoom: 2, rotation: 0, height: 500 };
  }
}
