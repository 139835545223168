
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import "vuelayers/lib/style.css"; // needs css-loader

export type MapItem = {
  id: string;
  coords: number[];
};

@Component({})
export default class OsmMap extends Vue {
  @Prop({
    default: (): any => {
      return [];
    },
  })
  readonly resources!: any[];

  @Prop({ default: false })
  readonly showFullscreen!: boolean;

  private hide = false;
  private hoveredItem: any = null;
  private cursorStyle = "";

  @Watch("resources")
  onResourcesChanged(value: any) {
    this.hide = true;
    this.$nextTick(() => {
      this.hide = false;
    });
  }

  @Prop({
    default: (): any => {
      return [9.1954236, 45.4671418];
    },
  })
  readonly center: number[];

  @Prop({
    default: (): any => {
      return {
        zoom: 2,
        minZoom: 5,
        maxZoom: 2,
        rotation: 0,
        height: 500,
      };
    },
  })
  readonly config: any;

  @Ref() readonly osmmap!: any;

  private selectedItem: MapItem | null = null;

  get zoom() {
    return this.config.zoom;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get minZoom() {
    return this.config.minZoom;
  }

  get hoveredPosition() {
    return this.hoveredItem?.coords || [0, 0];
  }

  get hoveredName() {
    return this.hoveredItem?.label;
  }

  get maxZoom() {
    return this.config.maxZoom;
  }

  get rotation() {
    return this.config.rotation;
  }

  get style() {
    return `height: ${this.config.height}px; ${this.cursorStyle}`;
  }

  get icon() {
    return require("@/assets/icons/map-pin.png");
  }

  get selectedId() {
    return this.selectedItem?.id;
  }

  get selectedIcon() {
    return require("@/assets/icons/blue-pin.png");
  }

  get colliderIcon() {
    return require("@/assets/icons/map-pin-collider.png");
  }

  onMapPointerMove(data: any) {
    const feature = this.osmmap.getFeaturesAtPixel(data.pixel)?.[0];
    if (feature) {
      this.cursorStyle = "cursor: pointer!important;";
      this.hoveredItem =
        this.resources.find(
          (o: any) => o.id.toString() === feature?.id_.toString()
        ) || null;
    } else {
      this.hoveredItem = null;
      this.cursorStyle = "";
    }
  }

  onClick(data: any) {
    const id = this.osmmap.getFeaturesAtPixel(data.pixel)?.[0]?.id_;
    if (!id) {
      this.selectedItem = null;
    } else {
      this.selectedItem =
        this.resources.find((o: any) => o.id.toString() === id.toString()) ||
        null;
      this.$emit("selected", id);
    }
  }
}
