var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"post-filters"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",class:{ 'flex-column': _vm.filtersAlignment === 'column' }},[_c('region-select',{staticClass:"fixed-width-select",class:[
        { 'pr-2': _vm.filtersAlignment === 'row' },
        { 'mb-3': _vm.filtersAlignment === 'column' },
      ],attrs:{"placeholder":"Regione","selected":_vm.filters.selectedRegion,"actsAsFilter":true,"filter":_vm.realmFilter},on:{"input":_vm.onRegionSelected}}),_c('organization-select',{staticClass:"fixed-width-select",class:[
        { 'pr-2': _vm.filtersAlignment === 'row' },
        { 'mb-3': _vm.filtersAlignment === 'column' },
      ],attrs:{"placeholder":"Amministrazione","selected":_vm.filters.selectedOrganization,"actsAsFilter":true,"filter":_vm.realmFilter},on:{"input":_vm.onSelectedOrganization}}),_c('post-typology-select',{staticClass:"fixed-width-select",class:[
        { 'pr-2': _vm.filtersAlignment === 'row' },
        { 'mb-3': _vm.filtersAlignment === 'column' },
      ],attrs:{"placeholder":"Tipologia"},model:{value:(_vm.filters.selectedTypology),callback:function ($$v) {_vm.$set(_vm.filters, "selectedTypology", $$v)},expression:"filters.selectedTypology"}}),(!_vm.disabled)?_c('div',{staticClass:"form-check"},[_c('div',{staticClass:"toggles"},[_c('label',{staticClass:"font-size-14",attrs:{"for":"myPosts"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.showCurrentUserPosts),expression:"filters.showCurrentUserPosts"}],attrs:{"type":"checkbox","id":"myPosts"},domProps:{"checked":Array.isArray(_vm.filters.showCurrentUserPosts)?_vm._i(_vm.filters.showCurrentUserPosts,null)>-1:(_vm.filters.showCurrentUserPosts)},on:{"change":function($event){var $$a=_vm.filters.showCurrentUserPosts,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.filters, "showCurrentUserPosts", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.filters, "showCurrentUserPosts", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.filters, "showCurrentUserPosts", $$c)}}}}),_c('span',{staticClass:"lever leverRight"}),_vm._v(" Solo i miei post ")])])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }