
import { Mixins, Component, Prop } from "vue-property-decorator";
import BarChart from "@/components/EChart/Bar.vue";
import { CHART_PRIMARY_COLOR, CHART_S1_COLOR, CHART_S2_COLOR, CHART_S3_COLOR, CHART_S4_COLOR } from "@/utils/Constants";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { monitoringStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import Spinner from "@/components/Common/Spinner.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { INDICATOR_DESCRIPTIONS } from "../../../utils/Constants";
import { MonitoringFilter } from "@/store/monitoring";

@Component({
  components: {
    BarChart,
    Spinner,
  },
})
export default class MonitoringSupplyItem extends Mixins(IndicatorMixin) {
  @Prop({ required: true }) readonly category!: string;
  @Prop({ required: true }) readonly code!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly familyDescription!: string;
  @Prop({ default: 90 }) readonly labelsWidth!: number;

  private report: any | null = null;

  @RegisterHttp(IndicatorMixinTags.IndicatorQualitative)
  indicatorsRequest!: RequestData;

  get loading(): boolean {
    return this.indicatorsRequest?.loading;
  }

  get showChart(): boolean {
    return !!this.plainValues?.labels.length && !!this.plainValues?.series?.length;
  }

  get palette(): string[] {
    return [CHART_PRIMARY_COLOR];
  }

  get groupedPalette(): Record<string, string> {
    return {
      'S1': CHART_S1_COLOR,
      'S2': CHART_S2_COLOR,
      'S3': CHART_S3_COLOR,
      'S4': CHART_S4_COLOR,
    }
  }

  get labelFontSize(): number {
    return this.code === "1" || this.code === "4" ? 11 : 9;
  }

  mounted(): void {
    this.loadData();
    StoreSubscriber.subscribe("monitoring/_setFilter", this.onFilterChanged);
  }

  get filter(): MonitoringFilter {
    return monitoringStore.filter;
  }

  onFilterChanged(value: any): void {
    this.$nextTick(() => {
      this.loadData();
    });
  }

  get payload(): any {
    return {
      filter: {
        category: this.category,
        code: this.code,
        ...this.filter,
      },
    };
  }

  assignLabels(report: any): any {
    const data: any[] = [];

    report.values.forEach((o: any) => {
      o.code = o.name;
      o.name = INDICATOR_DESCRIPTIONS[o.name]?.short;
      data.push(o);
    });
    report.values = data;

    return report;
  }

  async loadData(): Promise<void> {
    const data = await monitoringStore.getMonitoringSupplyData({
      code: this.code,
      category: this.category,
    });

    if (data) {
      this.report = this.assignLabels(data);
    }
  }

  get grouped(): boolean {
    return this.report?.grouped || false;
  }

  get plainValues(): any {
    if (this.grouped) {
      return {};
    }

    const values = [...this.report?.values || []];
    return {
      labels: values.map((o: any) => o.name),
      series: [{ data: values.map((o: any) => o.value) }],
      codes: values.map((o: any) => o.code),
    };
  }
}
