
import { Vue, Component, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import MonitoringQualitativeIndicatorList from "@/components/Indicators/MonitoringQualitative/List.vue";

@Component({
  components: {
    PageSection,
    MonitoringQualitativeIndicatorList,
  },
})
export default class MonitoringQualitativeIndicators extends Vue {}
