
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { ContractService, Initiative, Review, Service } from "../../models";

import VIcon from "@/components/VIcon.vue";
import VPopover from "@/components/Common/VPopover.vue";
import ServiceMixin from "@/mixins/http/ServiceMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { tagsStore } from "@/store/typed";
import TagBadge from "@/components/Common/TagBadge.vue";

@Component({
  components: {
    TagBadge,
    VIcon,
    VPopover,
  },
})
export default class InitiativeSideRecapItem extends Mixins(ServiceMixin) {
  @Prop({ required: true }) readonly initiative!: Initiative;

  private readMore = false;
  private showSharedDocuments = false;
  private allServices: Service[] = [];

  async created() {
    await tagsStore.loadTags();
    const [data, errors] = await safeAsync(this.getServices({}));

    if (data) {
      this.allServices = data;
    }
  }

  rating(initiative: Initiative) {
    return Math.floor(initiative?.contract?.review?.total || 0);
  }

  get tags() {
    return tagsStore.tags.filter((t) =>
      this.initiative.initiativeTags.some((it) => it.tag?.id === t.id)
    );
  }

  get services() {
    return this.initiative?.contract?.contractServices;
  }

  get step() {
    return Initiative.steps.find((o: any) => o.id === this.initiative.step);
  }

  get stepLabel() {
    return this.step?.label;
  }

  get stepClass() {
    return `indicator-${this.step?.class}`;
  }

  get initiativeHasSharedDocuments(): boolean {
    return this.initiative?.contract?.shareableDocuments?.length > 0;
  }

  getServiceIcon(index: number) {
    return this.initiative.contract.contractServices.find(
      (cs: ContractService) => cs.service.name.includes(index.toString())
    )
      ? { icon: `s${index}-active`, class: "active-icon" }
      : { icon: `s${index}-inactive`, class: "inactive-icon" };
  }

  getServiceName(index: number) {
    if (!this.allServices.length) {
      return;
    }

    const service = this.allServices?.find((s: Service) =>
      s.name?.includes(index.toString())
    );
    return service ? service.name : null;
  }

  ratingLabel(initiative: Initiative) {
    return Review.legends.find((r: any) => r.vote === this.rating(initiative))
      ?.label;
  }
}
