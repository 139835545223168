
import { Vue, Component, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import MonitoringSupplyList from "@/components/Indicators/MonitoringSupply/List.vue";
import ExecutiveContractsTrendList from "@/components/Indicators/ExecutiveContractTrend/List.vue";
import { monitoringDashboardTitleFromRealm } from "@/utils/Realms";

@Component({
  components: {
    PageSection,
    MonitoringSupplyList,
    ExecutiveContractsTrendList,
  },
})
export default class MonitoringIndicators extends Vue {
  print() {
    window.print();
  }

  get title(): string {
    return monitoringDashboardTitleFromRealm();
  }
}
