
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import InlineMultiSelect from "@/components/Common/VInlineMultiSelect.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import IndicatorMixin from "@/mixins/http/IndicatorMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import TagMixin, { TagMixinTags } from "@/mixins/http/TagsMixin";
import { tagsStore } from "@/store/typed";
import { Tag } from "@/models";

const tag = TagMixinTags.TagIndex;

@Component({ components: { InlineMultiSelect } })
export default class TagMultiselect extends Mixins(TagMixin) {
  @Prop() readonly idSelected!: string | number;
  @Prop({ default: (): any[] => [] }) readonly value!: any[] | null;
  @Prop() readonly formClass: string;
  @Prop() readonly searchEnabled: boolean;
  @Prop() readonly classes: string;
  @Prop() readonly dataKeys?: Array<string>;
  @Prop() readonly placeholder?: string;
  @Prop({ default: false }) readonly actsLikeFilter?: boolean;
  @Prop({ required: false }) readonly queryKey: string;
  @Prop({ default: true }) readonly preselect?: boolean;
  @Prop() readonly type: string;

  private options: any = [];

  private items: any = [];
  private id: any = this.idSelected || null;

  @RegisterHttp(tag) getItemsTag: RequestData;

  mounted() {
    this.loadItems();
  }

  get storeTags() {
    return tagsStore.tags;
  }

  onDataUpdated(data: any) {
    this.$emit("change", Object.values(data));
  }

  async loadItems() {
    await tagsStore.loadTags();
    const data = this.storeTags;
    if (data) {
      this.options = data.map((tag: Tag) => {
        return { id: tag.id, name: tag.name };
      });
      this.$emit("loaded", this.options);
    }
  }

  onItemsReady() {
    if (this.idSelected) {
      this.id = this.idSelected;
    } else {
      if (this.preselect) {
        this.id =
          this.queryKey && this.$route.query[this.queryKey]
            ? this.$route.query[this.queryKey]
            : this.options[0]?.id;
      }
    }
  }
}
