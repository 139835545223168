
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import PostFilters, {
  PostFiltersInterface,
} from "@/components/Post/Filters.vue";

@Component({
  components: {
    VModal,
    PostFilters,
  },
})
export default class PostFiltersModal extends Vue {
  @Prop({ required: true }) readonly value!: PostFiltersInterface;

  private filters: PostFiltersInterface | null = null;

  @Ref() readonly modal!: VModal;

  show() {
    if (this.value) {
      this.filters = this.value;
    }

    this.modal.show();
  }

  @Watch("value", { deep: true })
  onValueChanged() {
    if (this.value) {
      this.filters = this.value;
    }
  }

  hide() {
    this.modal.hide();
  }

  private emitFilters() {
    this.$emit("input", this.filters);
    this.hide();
  }
}
