
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";
import RegionMixin, { RegionTags } from "@/mixins/http/RegionMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { httpStore } from "@/store/typed";
import Throttler from "@/utils/Throttler";

@Component({ components: { VSelect } })
export default class RegionSelect extends Mixins(RegionMixin) {
  @RegisterHttp(RegionTags.RegionIndex)
  readonly getRequest!: RequestData;

  @Prop({ required: false })
  readonly selected!: any | null;

  @Prop()
  readonly queryKey?: string;

  @Prop()
  readonly label?: string;

  @Prop({ default: "Selezione una Regione" })
  readonly placeholder?: string;

  @Prop({ default: false })
  readonly preselect!: boolean;

  @Prop({ default: false })
  readonly actsAsFilter!: boolean;

  @Prop()
  readonly filter!: any;

  @Prop({ default: true })
  readonly searchable!: boolean;

  options: VSelectOption[] = [];

  private value: any | null = this.selected || null;

  private items: string[] = [];
  private term = "";
  private throttler: Throttler = new Throttler();

  mounted() {
    this.loadItems();
  }

  onSearched(term: string) {
    this.throttler.run(() => {
      this.term = term;
      this.loadItems();
    });
  }

  @Watch("selected")
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.onItemsReady();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100,
      },
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    if (this.term) {
      data.filter = { name: this.term };
    }
    return data;
  }

  get filterDefault() {
    return { id: "all", text: "Tutto" };
  }

  get loading() {
    return httpStore.status[this.tag]?.loading;
  }

  get tag() {
    return RegionTags.RegionIndex;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
    this.$emit(
      "inputRaw",
      this.items.find((o: string) => o === id)
    );

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.options.length) {
      return;
    }

    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0];
      }
    } else {
      this.value = this.options.find((o) => o.id === this.selected).id;
    }
  }

  async loadItems() {
    const [data, error] = await safeAsync(this.getRegions(this.payload));

    if (error) {
      console.log(error);
    } else {
      this.items = data;

      if (data.length) {
        this.options = this.items.map((o: string) => {
          return { id: o, text: o };
        });
      } else {
        this.options = [];
      }

      if (this.actsAsFilter) {
        this.options.unshift(this.filterDefault);
      }

      this.onItemsReady();
    }
  }
}
