
import { Vue, Component, Ref, Prop, Watch } from "vue-property-decorator";

import OrganizationSelect from "@/components/Organization/Select.vue";
import PostTypologySelect from "@/components/Post/TypologySelect.vue";
import RegionSelect from "@/components/RegionSelect.vue";
import { currentUserStore, zoneStore } from "@/store/typed";

export interface PostFiltersInterface {
  selectedTypology: string | null;
  selectedOrganization: string | null;
  selectedRegion: string | null;
  showCurrentUserPosts: boolean;
}

@Component({
  components: {
    OrganizationSelect,
    PostTypologySelect,
    RegionSelect,
  },
})
export default class PostList extends Vue {
  @Prop({ required: true }) readonly value!: PostFiltersInterface;
  @Prop({ default: "row" }) readonly filtersAlignment!: "row" | "column";

  private filters: PostFiltersInterface | null = null;

  created() {
    if (this.value) {
      this.filters = { ...this.value };
    }
  }

  @Watch("value", { deep: true })
  onValueChanged() {
    if (this.value) {
      this.filters = { ...this.value };
    }
  }

  get isMobile() {
    return this.$mq === "sm";
  }

  get disabled() {
    return (
      currentUserStore.user?.isOccUser || currentUserStore.user?.isConsipUser
    );
  }

  get realmFilter() {
    return { realm: zoneStore.realm?.id || "" };
  }

  @Watch("filters.selectedTypology")
  onChangedTypology(value: string) {
    this.filters.selectedTypology = value;
    this.emitFilters(this.filters);
  }

  @Watch("filters.showCurrentUserPosts")
  onChangedShowCurrentUserPosts() {
    this.emitFilters(this.filters);
  }

  private onSelectedOrganization(id: string) {
    this.filters.selectedOrganization = id;
    this.emitFilters(this.filters);
  }

  private onRegionSelected(value: string) {
    this.filters.selectedRegion = value;
    this.emitFilters(this.filters);
  }

  private emitModalFilters(filters: PostFiltersInterface) {
    this.$emit("input", filters);
  }

  private emitFilters(filters: PostFiltersInterface) {
    this.$emit("input", filters);
  }
}
