
import { Vue, Component, Prop } from "vue-property-decorator";
import { Post } from "../../models";

@Component({})
export default class PostCreateCard extends Vue {
  get postTypes() {
    return Post.types;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  onTypeSelected(type: string) {
    this.$emit("add", type);
  }
}
