
import { Mixins, Component, Prop } from "vue-property-decorator";
import BarChart from "@/components/EChart/Bar.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { monitoringStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import Spinner from "@/components/Common/Spinner.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { INDICATOR_DESCRIPTIONS } from "../../../utils/Constants";
import { MonitoringFilter } from "@/store/monitoring";
import { BarChartEntry } from "@/models/echart/types";

@Component({
  components: {
    BarChart,
    Spinner,
  },
})
export default class MonitoringSupplyGridItems extends Mixins(IndicatorMixin) {
  @Prop({ required: true }) readonly category!: string;
  @Prop({ required: true }) readonly code!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly familyDescription!: string;
  @Prop({ default: 90 }) readonly labelsWidth!: number;
  @Prop({ default: (): any => { return [] } }) readonly palette: string[];

  private report: any | null = null;

  @RegisterHttp(IndicatorMixinTags.IndicatorQualitative)
  indicatorsRequest!: RequestData;

  get loading(): boolean {
    return this.indicatorsRequest?.loading;
  }

  showChart(chartData: BarChartEntry): boolean {
    return !!chartData?.labels?.length && !!chartData.series?.length;
  }

  get labelFontSize(): number {
    return 11;
  }

  mounted(): void {
    this.loadData();
    StoreSubscriber.subscribe("monitoring/_setFilter", this.onFilterChanged);
  }

  get filter(): MonitoringFilter {
    return monitoringStore.filter;
  }

  onFilterChanged(value: any): void {
    this.$nextTick(() => {
      this.loadData();
    });
  }

  get payload(): any {
    return {
      filter: {
        category: this.category,
        code: this.code,
        ...this.filter,
      },
    };
  }

  assignLabels(report: any): any {
    const data: any[] = [];

    report.values.forEach((o: any) => {
      o.code = o.name;
      o.name = INDICATOR_DESCRIPTIONS[o.name]?.short;
      data.push(o);
    });
    report.values = data;

    return report;
  }

  async loadData(): Promise<void> {
    const data = await monitoringStore.getMonitoringSupplyData({
      code: this.code,
      category: this.category,
    });

    if (data) {
      this.report = this.assignLabels(data);
    }
  }

  get grouped(): boolean {
    return this.report?.grouped || false;
  }

  get values(): Array<BarChartEntry> {
    const values = [...this.report?.values || []];

    return values.map((indValues: any) => {
      return {
        codes: [...indValues.groupLabels || [] as string[]],
        labels: [...indValues.groupLabels || [] as string[]],
        series: [{ data: [...indValues.groupValues || []] }]
      }
    })
  }

  legendForCodes(codes: string[]): string[] {
    return codes.map(code => {
      return `${INDICATOR_DESCRIPTIONS[code]?.short || ''}: ${INDICATOR_DESCRIPTIONS[code]?.long || ''}`;
    })
  }
}
