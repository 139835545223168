
import MyDesk from "@/components/Monitoring/MyDesk.vue";
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";

import { currentUserStore } from "@/store/typed";
@Component({
  components: {
    MyDesk,
    PageSection,
    StickyMenu,
  },
})
export default class PostFeed extends Vue {
  private links: StickyMenuLink[] = [
    { routeName: "monitoring_post_feed", icon: "it-inbox", name: "Forum/wiki" },
    {
      routeName: "monitoring_initiative_map",
      icon: "it-pin",
      name: "Hub del riuso",
    },
    {
      routeName: "monitoring_dashboard",
      icon: "it-presentation",
      name: "Dashboard",
    },
  ];

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }
}
