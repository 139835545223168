
import { Vue, Component, Prop } from "vue-property-decorator";

import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";

@Component({
  components: {
    VSelect,
  },
})
export default class PostTypologySelect extends Vue {
  @Prop({ required: false }) readonly value!: VSelectOption;
  @Prop({ default: "Seleziona una tipologia" }) readonly placeholder!: string;

  private options: VSelectOption[] = [];

  created() {
    this.options = [
      { id: "all", text: "Tutto" },
      { id: "breaking_news", text: "Breaking News" },
      { id: "webinar", text: "Webinar" },
      { id: "event", text: "Evento" },
    ];
  }

  private onSelected(value: VSelectOption) {
    this.$emit("input", value);
  }
}
