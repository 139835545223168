
import { Vue, Component } from "vue-property-decorator";
import MonitoringSupplyItem from "@/components/Indicators/MonitoringSupply/Item.vue";
import MonitoringSupplyGridItems from "@/components/Indicators/MonitoringSupply/GridItems.vue";
import { CHART_S1_COLOR, CHART_S2_COLOR, CHART_S3_COLOR, CHART_S4_COLOR, INDICATOR_DESCRIPTIONS } from "@/utils/Constants";

@Component({
  components: {
    MonitoringSupplyItem,
    MonitoringSupplyGridItems
  },
})
export default class MonitoringQualitativeIndicatorList extends Vue {
  get charts() {
    return [
      {
        category: "IM",
        code: "1",
        labelsWidth: 90,
        grid: false,
        palette: []
      },
      {
        category: "IM",
        code: "2",
        labelsWidth: 50,
        grid: true,
        palette: [CHART_S1_COLOR, CHART_S2_COLOR, CHART_S3_COLOR, CHART_S4_COLOR]
      },
      {
        category: "IM",
        code: "3",
        labelsWidth: 60,
        grid: false,
        palette: []
      },
      {
        category: "IM",
        code: "4",
        labelsWidth: 90,
        grid: false,
        palette: []
      },
    ];
  }

  get chartsFullData() {
    const data: any = [];
    this.charts.forEach((o: any) => {
      const key = `${o.category}-${o.code}`;
      o.label = INDICATOR_DESCRIPTIONS[key]?.long;
      o.familyDescription = INDICATOR_DESCRIPTIONS[key]?.familyDescription;
      data.push(o);
    });

    return data;
  }
}
