
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import GaugeChart from "@/components/EChart/Gauge.vue";
import ExecutiveContractTrendReport from "@/models/ExecutiveContractTrendReport";
import { CHART_PRIMARY_COLOR, INDICATOR_DESCRIPTIONS } from "@/utils/Constants";
import DescriptionModal from "@/components/Common/DescriptionModal.vue";

@Component({
  components: {
    GaugeChart,
    DescriptionModal,
  },
})
export default class ExecutiveContractTrendItem extends Vue {
  @Prop({ required: true }) readonly report!: ExecutiveContractTrendReport;

  @Ref() readonly descriptionModal!: DescriptionModal;

  get palette() {
    return [CHART_PRIMARY_COLOR];
  }

  get value() {
    return this.report.data;
  }

  get label() {
    const key = `${this.report.category}-${this.report.code}`;
    return INDICATOR_DESCRIPTIONS[key]?.short;
  }

  private openDescriptionModal() {
    const key = `${this.report.category}-${this.report.code}`;
    const longDes = INDICATOR_DESCRIPTIONS[key].long;
    this.descriptionModal.show(longDes, key);
  }
}
