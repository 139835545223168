
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import InitiativeMap from "@/components/Initiative/Map.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";

@Component({
  components: {
    VModal,
    InitiativeMap: () => import("@/components/Initiative/Map.vue"),
  },
})
export default class InitiativeModal extends Vue {
  @Ref() readonly modal!: VModal;

  show() {
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  get mapConfig() {
    return {
      zoom: 2,
      minZoom: 5,
      maxZoom: 2,
      rotation: 0,
      height: window.innerHeight - 120,
    };
  }
}
