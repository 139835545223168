
import { Component, Mixins, Ref } from "vue-property-decorator";

import PostMixin, { PostMixinTags } from "@/mixins/http/PostMixin";
import { Post } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";

import PostItem from "@/components/Post/Item.vue";
import OrganizationSelect from "@/components/Organization/Select.vue";
import PostTypologySelect from "@/components/Post/TypologySelect.vue";
import RegionSelect from "@/components/RegionSelect.vue";
import { currentUserStore, zoneStore } from "@/store/typed";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import PostFilters, {
  PostFiltersInterface,
} from "@/components/Post/Filters.vue";
import PostFiltersModal from "@/components/Post/FiltersModal.vue";
import VSvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    PostItem,
    OrganizationSelect,
    PostTypologySelect,
    RegionSelect,
    PostFilters,
    PostFiltersModal,
    VSvg,
  },
})
export default class PostList extends Mixins(PostMixin) {
  private posts: Post[] = [];
  private page = 1;

  private filters: PostFiltersInterface = {
    selectedTypology: null,
    selectedOrganization: null,
    selectedRegion: null,
    showCurrentUserPosts: false,
  };

  @Ref() readonly postFiltersModal!: PostFiltersModal;

  @RegisterHttp(PostMixinTags.PostIndex)
  readonly postsIndexRequest!: RequestData;

  get isMobile() {
    return this.$mq === "sm";
  }

  get loading() {
    return this.postsIndexRequest?.loading;
  }

  async created() {
    await this.loadPosts(this.page);
  }

  private onFiltersChanged(filters: PostFiltersInterface) {
    this.filters = filters;
    this.loadPosts(1);
  }

  private loadMore() {
    if (this.loading || this.postsIndexRequest?.meta.nextPage === null) {
      return;
    }

    this.page++;
    this.loadPosts(this.page);
  }

  reload() {
    this.loadPosts();
  }

  private async loadPosts(page = 1) {
    const payload: any = {
      include: "",
      page: page,
      filter: {
        realm: zoneStore.realm.id,
      },
    };

    payload.include = "user.organization";
    if (
      this.filters.selectedOrganization &&
      this.filters.selectedOrganization !== "all"
    ) {
      payload.filter.organization = this.filters.selectedOrganization;
    }
    if (
      this.filters.selectedTypology &&
      this.filters.selectedTypology !== "all"
    ) {
      payload.filter.post_type = this.filters.selectedTypology;
    }
    if (this.filters.selectedRegion && this.filters.selectedRegion !== "all") {
      payload.filter.region = this.filters.selectedRegion;
    }
    if (this.filters.showCurrentUserPosts) {
      payload.filter.user = currentUserStore.user.id;
    }

    const [data, errors] = await safeAsync(this.getPosts(payload));

    if (errors) {
      showErrorToast("Sì è verificato un errore nel caricare i post");
      return;
    }

    if (page === 1) {
      this.posts = [...data];
    } else {
      this.posts.push(...data);
    }
  }
}
